import { useContext, createContext, PropsWithChildren, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useUser } from '@context/user';
import { DIRECTION, ROUTING_STATUS, SELECTED_DATE_TAB } from '@customers/network-generation/constants';
import useFilters from '@hooks/use-filters';
import { useRouter } from 'next/router';
import { useCustomers } from '@context/customers';

type ReservationsContextProps = {
  reservationsFilters: ReservationsFiltersProps | null;
  setReservationsFilters: (filters: Partial<ReservationsFiltersProps>) => void;
};

type ReservationsFiltersProps = {
  activeTab: ROUTING_STATUS;
  date: Date | string;
  dateTab: SELECTED_DATE_TAB;
  direction: DIRECTION;
};

const ReservationsContext = createContext<ReservationsContextProps>({
  reservationsFilters: null,
  setReservationsFilters: () => undefined as void,
});

export const ReservationsProvider = ({ children }: PropsWithChildren<unknown>) => {
  const router = useRouter();
  const { selectedCorporateId: storedCorporateId } = useCustomers();
  const { user } = useUser();
  const [reservationsFilters, setReservationsFilters] = useFilters<ReservationsFiltersProps>(
    {
      activeTab: ROUTING_STATUS.PENDING,
      date: new Date(),
      dateTab: SELECTED_DATE_TAB.TODAY,
    },
    router.asPath.includes('customers'),
  );

  useEffect(() => {
    const dataLayer: { [key: string]: string } = {};
    storedCorporateId && (dataLayer['corporate_id'] = storedCorporateId);
    user?.id && (dataLayer['user_id'] = user.id);
    reservationsFilters?.dateTab && (dataLayer['selected_date_tab'] = reservationsFilters.dateTab);
    reservationsFilters?.date && (dataLayer['selected_date'] = reservationsFilters?.date?.toString());
    reservationsFilters?.direction && (dataLayer['shift_direction'] = reservationsFilters.direction);
    reservationsFilters?.activeTab && (dataLayer['routing_status_tab'] = reservationsFilters.activeTab);

    if (Object.keys(dataLayer).length !== 0) {
      TagManager.dataLayer({
        dataLayer,
      });
    }
  }, [
    reservationsFilters.activeTab,
    reservationsFilters?.date,
    reservationsFilters.dateTab,
    reservationsFilters.direction,
    storedCorporateId,
    user?.id,
  ]);

  return (
    <ReservationsContext.Provider
      value={{
        reservationsFilters,
        setReservationsFilters,
      }}
    >
      {children}
    </ReservationsContext.Provider>
  );
};

export const useReservations = () => useContext(ReservationsContext);
