import { path } from 'static-path';

// Customers
const customers = path('/customers/:subModule');
const employees = customers({ subModule: 'employees' });
const corporates = customers({ subModule: 'corporates' });
const createCorporate = customers({ subModule: 'create-corporate' });
const privateRides = customers({ subModule: 'private-ride' });

// Planning
const fleet = path('/fleet/:subModule');
const verifications = fleet({ subModule: 'verifications' });

// Planning
const planning = path('/planning/:subModule');
const shifts = planning({ subModule: 'shifts' });
const networkGeneration = planning({ subModule: 'network-generation' });
const reservations = planning({ subModule: 'reservations' });

// Demand
const demand = path('/demand/:subModule');
const corePricing = demand({ subModule: 'core-pricing' });
const b2bPricing = demand({ subModule: 'b2b-pricing' });

// Hint: add more modules
// Bulk Actions
const bulkActions = path('/bulk-actions/:subModule');
const planCreation = bulkActions({ subModule: 'plan-creation' });

// Supply
const supply = path('/supply/:subModule');
const planGeneration = supply({ subModule: 'plan-generation' });
const supplyPlans = supply({ subModule: 'plans' });

const supplyPricing = supply({ subModule: 'supply-pricing' });

// Private Bus
const privateBus = path('/private-bus/:subModule');
const privateBusRequests = privateBus({ subModule: 'requests' });
const privateBusPricing = privateBus({ subModule: 'pricing' });

// Access Control
const accessControl = path('/access-control/:subModule');
const users = accessControl({ subModule: 'users' });
const roles = accessControl({ subModule: 'roles' });

// Invoice
const invoice = path('/invoice/:subModule');
const invoiceRiderTaxConfiguration = invoice({ subModule: 'rider-tax-configurations' });
const invoiceSupplyTaxConfiguration = invoice({ subModule: 'supply-tax-configurations' });

// Hint: add more modules

export {
  customers,
  planning,
  supply,
  shifts,
  networkGeneration,
  reservations,
  planGeneration,
  supplyPricing,
  supplyPlans,
  bulkActions,
  planCreation,
  employees,
  corporates,
  privateRides,
  demand,
  corePricing,
  b2bPricing,
  privateBus,
  privateBusPricing,
  privateBusRequests,
  users,
  roles,
  accessControl,
  createCorporate,
  invoiceRiderTaxConfiguration,
  invoiceSupplyTaxConfiguration,
  fleet,
  verifications,
};
